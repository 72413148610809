import mtv from '../assets/img/mtv.svg';
import {Container, Grid} from '@mui/material';

const links = [
    {id: 0, name: 'Paramount+', link: 'https://www.paramountplus.com/intl/'},
    {id: 1, name: 'FAQ/Help', link: 'https://viacom.helpshift.com/hc/en/18-mtv/'},
    {id: 2, name: 'Terms of Use', link: 'https://www.mtv.com/legal/o3ccr8/terms-of-use'},
    {id: 3, name: 'Privacy Policy', link: 'https://privacy.paramount.com/en/policy?r=www.mtv.com'},
    {id: 4, name: 'Careers', link: 'https://careers.paramount.com/'},
    {id: 5, name: 'Keep Paramount', link: 'https://www.keepparamount.com/'},
    {id: 6, name: 'MTV Casting Call', link: 'https://www.mtv.com/browse/casting-calls-mtv'},
]
export default function Footer(){
    return(
        <div className="mtv_footer pt-3 pb-5">
            <Container>
            <div className="links_footer pb-3 text-bold">
                {links && links.map(l => 
                    <div onClick={() => window.open(l.link, '_blank')} key={l.id}>
                        {l.name}
                    </div>
                )}
            </div>
            <div className="display-icons pt-3 pb-3">
                <div>
                    <div onClick={() => window.open('https://www.instagram.com/mtv/', '_blank')} className="svg_footer">
                        <svg aria-hidden="true" data-display-name="Icon" focusable="false" preserveAspectRatio="xMinYMin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" data-icon-name="Instagram" title="Instagram-icon" className="css-13ftw69 elqon7a0">
                            <path d="M16 .063q-3.25 0-4.281.016T9.406.157t-2.203.25-1.672.5q-.813.313-1.5.734T2.687 2.75q-.656.656-1.094 1.344t-.75 1.469-.5 1.688-.25 2.188-.078 2.313-.016 4.281.016 4.281.078 2.281q.063 1.281.25 2.203t.5 1.672q.313.813.75 1.5t1.094 1.344 1.344 1.094 1.5.75q.75.281 1.672.484t2.203.266q1.281.031 2.313.063t4.281.031 4.281-.031 2.313-.063q1.281-.063 2.203-.266t1.672-.484q.781-.313 1.469-.75t1.375-1.094q.656-.656 1.094-1.344t.75-1.5q.281-.75.484-1.672t.266-2.203q.031-1.25.063-2.281t.031-4.281-.031-4.281-.063-2.313q-.063-1.281-.266-2.188t-.484-1.688q-.313-.781-.75-1.469T29.313 2.75q-.688-.688-1.375-1.109T26.469.907q-.75-.313-1.672-.5t-2.203-.25-2.313-.078T16 .063zm0 2.875q3.188 0 4.188.016t2.281.078q1.156.063 1.844.219t1.125.344q.563.219.984.484t.859.703q.406.406.688.844t.5 1q.156.406.328 1.094T29 9.564q.063 1.281.078 2.281t.016 4.188-.016 4.188-.078 2.25q-.031 1.188-.203 1.875t-.328 1.094q-.219.563-.5.984t-.688.859q-.438.406-.859.688t-.984.5q-.438.156-1.125.328t-1.844.234q-1.281.063-2.281.078T16 29.127q-3.219 0-4.219-.016t-2.25-.078q-1.156-.063-1.859-.234t-1.109-.328q-.563-.219-.984-.5t-.859-.688q-.406-.438-.688-.859t-.5-.984q-.156-.406-.328-1.094t-.234-1.875q-.063-1.25-.078-2.25t-.016-4.188.016-4.188.078-2.281q.063-1.156.234-1.844t.328-1.094q.219-.563.5-1t.688-.844q.438-.438.859-.703t.984-.484q.406-.188 1.109-.344t1.859-.219q1.25-.063 2.25-.078T16 2.938zm0 18.406q-2.219 0-3.781-1.563t-1.563-3.75q0-2.219 1.563-3.766T16 10.718t3.766 1.547 1.547 3.766q0 2.188-1.547 3.75T16 21.344zm0-13.5q-1.719 0-3.188.625-1.5.656-2.625 1.766t-1.75 2.609q-.656 1.5-.656 3.188t.656 3.188q.625 1.5 1.75 2.609t2.625 1.766q1.469.625 3.188.625 1.688 0 3.188-.625 1.5-.656 2.609-1.766t1.766-2.609.656-3.188-.656-3.188-1.766-2.609-2.609-1.766q-1.5-.625-3.188-.625zM26.438 7.5q0 .813-.547 1.359t-1.359.547q-.781 0-1.344-.547T22.625 7.5q0-.781.563-1.344t1.344-.563q.813 0 1.359.563t.547 1.344z"></path></svg>
                    </div>
                </div>
                <div>
                    <div onClick={() => window.open('https://www.facebook.com/MTV', '_blank')} className="svg_footer">
                        <svg aria-hidden="true" data-display-name="Icon" focusable="false" preserveAspectRatio="xMinYMin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" data-icon-name="Facebook" title="Facebook-icon" className="css-13ftw69 elqon7a0"><path d="M30.219 0H1.75Q1.031 0 .516.516T0 1.75v28.469q0 .75.516 1.266t1.234.516h15.344V19.595h-4.188v-4.813h4.188v-3.563q0-3.094 1.719-4.734t4.469-1.641q1.344 0 2.375.063t1.375.125v4.313h-2.563q-1.5 0-1.953.656t-.453 1.688v3.094h4.781l-.625 4.813h-4.156v12.406h8.156q.75 0 1.266-.516t.516-1.266V1.751q0-.719-.516-1.234T30.219.001z"></path></svg>
                    </div>
                </div>
                <div>
                    <div onClick={() => window.open('https://x.com/MTV', '_blank')} className="svg_footer">
                        <svg aria-hidden="true" data-display-name="Icon" focusable="false" preserveAspectRatio="xMinYMin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 1227" data-icon-name="Twitter" title="Twitter-icon" className="css-13ftw69 elqon7a0"><path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"></path></svg>
                    </div>
                </div>
                <div>
                    <div onClick={() => window.open('https://www.tiktok.com/@mtv?lang=en', '_blank')} className="svg_footer">
                        <svg aria-hidden="true" data-display-name="Icon" focusable="false" preserveAspectRatio="xMinYMin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" data-icon-name="TikTok" title="TikTok-icon" className="css-13ftw69 elqon7a0"><path d="M19.8 5.1c-.2-.1-.3-.1-.5-.2-.4-.3-.9-.6-1.2-1-.9-1-1.2-2.1-1.4-2.8-.1-.6-.1-1-.1-1h-4.1v16.6c-.1 1.2-.7 2.2-1.8 2.8-.5.3-1.1.5-1.7.4-1.9 0-3.5-1.6-3.5-3.5s1.6-3.5 3.5-3.5c.4 0 .7.1 1.1.2V8.9c-2.1-.3-4.3.4-5.9 1.7-.6.6-1.2 1.4-1.7 2.2-.2.3-.8 1.5-.9 3.5 0 1.1.3 2.3.4 2.7.1.3.5 1.2 1.1 2 .5.6 1.1 1.2 1.8 1.7C7 24.1 9.2 24 9.2 24c.4 0 1.7 0 3.1-.7 1.6-.8 2.5-1.9 2.5-1.9.6-.7 1.1-1.5 1.4-2.3.4-1 .5-2.2.5-2.6V8l.7.5s1 .6 2.5 1c1.1.3 2.5.3 2.5.3v-4c-.5 0-1.5-.1-2.6-.7z"></path></svg>
                    </div>
                </div>
                <div>
                    <div onClick={() => window.open('https://www.youtube.com/user/MTV', '_blank')} className="svg_footer">
                        <svg aria-hidden="true" data-display-name="Icon" focusable="false" preserveAspectRatio="xMinYMin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" data-icon-name="Youtube" title="Youtube-icon" className="css-13ftw69 elqon7a0"><path d="M13,21 L13,11 L21,16 L13,21 Z M28.5022184,5.18670038 C26.0067004,4.5 15.9998683,4.5 15.9998683,4.5 C15.9998683,4.5 5.99329958,4.5 3.49778162,5.18670038 C2.1209532,5.56453434 1.03676997,6.67802211 0.668790386,8.09145108 C0,10.6537982 0,16 0,16 C0,16 0,21.3462018 0.668790386,23.9085489 C1.03676997,25.3219779 2.1209532,26.4354657 3.49778162,26.8132996 C5.99329958,27.5 15.9998683,27.5 15.9998683,27.5 C15.9998683,27.5 26.0067004,27.5 28.5022184,26.8132996 C29.8790468,26.4354657 30.96323,25.3219779 31.331473,23.9085489 C32,21.3462018 32,16 32,16 C32,16 32,10.6537982 31.331473,8.09145108 C30.96323,6.67802211 29.8790468,5.56453434 28.5022184,5.18670038 Z"></path></svg>
                    </div>
                </div>
                <div>
                    <div onClick={() => window.open('https://www.threads.net/@mtv', '_blank')} className="svg_footer">
                        <svg aria-hidden="true" data-display-name="Icon" focusable="false" preserveAspectRatio="xMinYMin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" data-icon-name="Threads" title="Threads-icon" className="css-13ftw69 elqon7a0"><path d="M12.8594 12.2793C12.3546 11.9434 10.677 10.7762 10.677 10.7762C12.0916 8.74849 13.9575 7.95907 16.5367 7.95907C18.3607 7.95907 19.9095 8.57401 21.0153 9.73781C22.1215 10.902 22.7525 12.5668 22.8961 14.6958C23.5093 14.9529 24.0756 15.2557 24.5897 15.6021C26.6632 16.9997 27.805 19.0893 27.805 21.4851C27.805 26.5769 23.6402 31 16.1013 31C9.62844 31 2.90356 27.2261 2.90356 15.9888C2.90356 4.81538 9.41753 1 16.0831 1C19.1612 1 26.3811 1.45569 29.0962 10.4417L26.5501 11.1036C24.4508 4.70146 20.0493 3.6825 16.0137 3.6825C9.34257 3.6825 5.56912 7.75367 5.56912 16.4152C5.56912 24.1815 9.78683 28.3073 16.1022 28.3073C21.2978 28.3073 25.1716 25.6011 25.1716 21.6396C25.1716 18.9436 22.9113 17.6527 22.7957 17.6527C22.3544 19.9659 21.1711 23.8571 15.9768 23.8571C12.9504 23.8571 10.3412 21.762 10.3412 19.0169C10.3412 15.0969 14.0516 13.6781 16.9818 13.6781C18.0791 13.6781 19.4034 13.7522 20.0929 13.8928C20.0929 12.6981 19.0845 10.6534 16.5371 10.6534C14.2015 10.653 13.609 11.4124 12.8594 12.2793ZM17.3401 16.3555C13.523 16.3555 13.0292 17.9865 13.0292 19.0109C13.0292 20.6567 14.9803 21.2022 16.0213 21.2022C17.9296 21.2022 19.89 20.6719 20.1983 16.6575C19.2306 16.4398 18.5094 16.3555 17.3401 16.3555Z"></path></svg>
                    </div>
                </div>
            </div> 
            <div className="display-center pt-3 pb-4">
                <img src={mtv} alt="mtv" />
            </div>
            <div className="text-center text-bold copyright">© 2024 Viacom International Inc. All Rights Reserved. MTV, EMA and all related titles, logos and characters are trademarks of Viacom International Inc</div>
            </Container>
        </div>
    )
}