import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import mtv from '../assets/img/mtv.svg';
import {useNavigate} from 'react-router-dom';
const drawerWidth = 400;
const navItems = ['$MTV', 'Utility', 'Vote'];

export default function Navigation(){
    const container = window !== undefined ? () => window.document.body : undefined;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const navigate = useNavigate();
    const [appBarClass, setAppBarClass] = React.useState('nav_transparent'); 
    const link = process.env.REACT_APP_LINK;

    const goMint = () => {
        window.open(link, '_blank')
    }

    const handleScroll = () => {
        const offset = window.scrollY; // Get vertical scroll position
        if (offset > 100) { // Change threshold as needed
            setAppBarClass('nav_black'); // Change to black class
        } else {
            setAppBarClass('nav_transparent'); // Change back to transparent class
        }
    };

    React.useEffect(() => {
    window.addEventListener('scroll', handleScroll); // Listen for scroll events
    return () => {
        window.removeEventListener('scroll', handleScroll); // Cleanup on unmount
    };
    }, []);

    const handleDrawerToggle = () => {
      setMobileOpen((prevState) => !prevState);
    };

    const navigateHash = (value) => {
        navigate('/#'+value.toLowerCase())
    }

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <div className="pt-3 pb-3">
                <img src={mtv} alt="mtv" />
            </div>
          <Divider />
          <List>
            {navItems.map((item) => (
              <ListItem onClick={() => navigateHash(item)} key={item} disablePadding>
                <ListItemButton sx={{ textAlign: 'center' }}>
                  <ListItemText className="text-bold" primary={item} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
    );

    return(
        <>
        <AppBar className={appBarClass} component="nav">
            <Toolbar>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
            >
                <MenuIcon />
            </IconButton>
            <IconButton className="me-2">
                <img src={mtv} alt="mtv" />
            </IconButton>
            <Box sx={{ display: { xs: 'none', sm: 'flex' }, gap: '1rem', flexGrow: '1' }}>
                {navItems.map((item) => (
                <div onClick={() => navigateHash(item)} className="text-bold font_nav" key={item} sx={{ color: '#fff' }}>
                    {item}
                </div>
                ))}
            </Box>
            <Box sx={{display: {xs: 'block', sm: 'none'}, flexGrow: '1'}}>
                <div></div>
            </Box>
            <Box sx={{flexGrow: '1', justifyContent: 'flex-end', display: 'flex'}}>
                <Button onClick={() => goMint()} variant="contained" className="btn_style ps-4 pe-4">Purchase $MTV</Button>
            </Box>
            </Toolbar>
        </AppBar>
        <nav>
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
                {drawer}
            </Drawer>
        </nav>
        </>
    )
}