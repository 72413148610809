import './assets/css/App.css';
import Navigation from './layouts/Navigation';
import {Container, Grid, Button} from '@mui/material';
import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

import gift1 from './assets/img/_GIF_7_optimized.gif';
import mtv from './assets/img/mtv.svg';
import f from './assets/img/f.txt';
import s from './assets/img/s.txt';
import m from './assets/img/m.txt';
import plogo from './assets/img/pplus_marketing_site_logo_white.png';

import Footer from './layouts/Footer';

function App() {
  const { hash } = useLocation();
  const link = process.env.REACT_APP_LINK;

  useEffect(() => {
    const element = document.getElementById(hash.replace("#", ""));
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [hash]);

  const goMint = () => {
    window.open(link, '_blank')
  }
  return (
    <div className="App">
        <Navigation />
        <header className="App-header">
          <div className="App-header-content">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <div className="display-center full-width">
                  <img src={plogo} className="pimg" alt="paramount" />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="display-col-center">
                  <h3 className="text-bold text-center">12 Months Free Subscription</h3>
                  <p style={{fontSize: '1rem'}} className="text-center">Only for $MTV Holders
                    <br/>
                    <span className="text-bold" style={{fontSize: '.73rem'}}>
                      Token Address: 2uYmc5ZEp2T1hK5NiWEx34vLa9yJuFLRcNB8UwPUpump
                    </span>
                  </p>
                  <Button onClick={() => goMint()} className="btn_style full-width" color="secondary" variant="contained">START NOW</Button>
                </div>
              </Grid>
            </Grid>
           
           
          </div>
        </header>

        <section id="$mtv" className="pt-5 pb-5">
          <Container style={{position: 'relative'}}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <div className="h1_src1">
                  <h1 className="text-bold ">Presenting <br/>
                  <span className="mtv_big text-secondary">$MTV</span><br/>
                  Decentralized</h1>
                  
                  <div className="pt-4">
                    <Button onClick={() => goMint()} className="btn_style ps-5 pe-5" variant="contained" color="secondary">+ Purchase Now</Button>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <img className="img_mtv_resp" style={{width: '100%', maxWidth: '25rem'}}  src={mtv} alt="mtv" />
              </Grid>
            </Grid>
          
          </Container>
        </section>

        <section className="bg_black pt-5 pb-5">
          <Container className="other_container">
            <img style={{width: '100%'}} src={gift1} alt="gif" />
          </Container>
        </section>

        <section id="utility" className="pt-5 pb-5">
          <Container>
            <h2>
              <span className="text-bold mtv_big text-secondary">$MTV Holders</span>
            </h2>
            <Grid className="pt-4 pb-4" container spacing={2}>
              <Grid item xs={12} sm={4}>
                <div className="cont_base">
                  <p>Exclusive video content, music releases, behind-the-scenes footage, and interviews
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className="cont_base"> 
                  <p>Unlock ad-free viewing or listening on MTV platforms.</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className="cont_base">
                  <p>Early access to upcoming episodes, music drops, and events.</p>
                </div>
              </Grid>
            </Grid>
            </Container>
          </section>

          <section className="bg_black pt-5 pb-5">
            <div className="img_absolute_utility">
              <div className="bg_black_left"></div>
              <div className="pic_bg1">
                <img src={s} alt="f" />
              </div>
            </div>
          
          <Container style={{position: 'relative', zIndex: 4}}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}></Grid>
              <Grid item xs={12} sm={6}>
                <h3 className="text-bold text-center pb-2">Loyalty and Rewards</h3>
                  <p className="text-center pb-2">‣ Earn tokens by watching shows, music videos, or advertisements.
                  <br/>
                  ‣ Tip to support new music artists or creators featured on MTV, with tokens going directly to the artist.
                </p>
                <Button onClick={() => goMint()} className="btn_style full-width ps-5 pe-5" variant="contained" color="secondary">+ Purchase Now</Button>
              </Grid>
            </Grid>
          </Container>
          </section>

          <section id="vote" className="bg_black pt-5 pb-5 mt-5">
            <div className="img_absolute_utility">
              <div className="pic_bg">
                <img src={f} alt="f" />
              </div>
              <div className="bg_black_right"></div>
            </div>

            <Container style={{position: 'relative', zIndex: 4}}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <h3 className="text-bold text-center pb-2">Introducing Vote</h3>
                <p className="text-center pb-2">‣ Vote on award shows, music rankings, or even episode outcomes for MTV shows
                <br/>‣ Gain access to exclusive live streams, chats and virtual meet-and-greets with your favorite artists or influencers
                <br/>‣ Participate in polls that influence the brand’s direction, content selection and themes for upcoming shows.</p>
                <Button onClick={() => goMint()} className="btn_style full-width ps-5 pe-5" variant="contained" color="secondary">+ Purchase Now</Button>
              </Grid>
              <Grid item xs={12} sm={6}>
              </Grid>
            </Grid>
            </Container>
          </section>

          <section className="bg_black pt-5 pb-5 mt-5 mb-5">
          
          <div className="img_absolute_utility">
              <div className="pic_bg1">
                <img src={m} alt="f" />
              </div>
              <div className="bg_black_left"></div>
            </div>
          <Container style={{position: 'relative', zIndex: 4}}>
          <Grid container spacing={2}>
              <Grid item xs={12} sm={6}></Grid>
              <Grid item xs={12} sm={6}>
                <h3 className="text-bold text-center pb-2">Decentralized Collectibles and Merchandise</h3>
                <p className="text-center pb-2">‣ Exclusive NFTs for events like the Video Music Awards and memorabilia for iconic moments in music and television history.
                <br/>‣ Redeemed tokens for physical or digital merchandise and collect exclusive branded items or limited-edition clothing.
                <br/>‣ Concert or event tickets as NFTs with unique perks for our token holders.</p>
                <Button onClick={() => goMint()} className="btn_style full-width ps-5 pe-5" variant="contained" color="secondary">+ Purchase Now</Button>
              </Grid>
            </Grid>
            
          </Container>
          
          </section>

          {/** 
          <section className="pb-5">
            <Container>
            <h3 className="text-bold pt-5">‣ Introducing Vote</h3>
            <Grid className="pt-4 pb-4" container spacing={2}>
              <Grid item xs={12} sm={4}>
                <div className="cont_base">
                  <p>Vote on award shows, music rankings, or even episode outcomes for MTV shows</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className="cont_base">
                  <p>Gain access to exclusive live streams, chats and virtual meet-and-greets with your favorite artists or influencers</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className="cont_base">
                  <p>Participate in polls that influence the brand’s direction, content selection and themes for upcoming shows.</p>
                </div>
              </Grid>
            </Grid>

            <h3 className="text-bold pt-5">‣ Decentralized Collectibles and Merchandise</h3>
            <Grid className="pt-4 pb-4"container spacing={2}>
              <Grid item xs={12} sm={4}>
                <div className="cont_base">
                  <p>Exclusive NFTs for events like the Video Music Awards and memorabilia for iconic moments in music and television history.</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className="cont_base">
                  <p>Redeemed tokens for physical or digital merchandise and collect exclusive branded items or limited-edition clothing.</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className="cont_base">
                  <p>Concert or event tickets as NFTs with unique perks for our token holders.</p>
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>*/}
        <Footer />
    </div>
  );
}

export default App;
