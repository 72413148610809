import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/cbootstrap.scss';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#EE3030', // Custom Primary Color
    },
    secondary: {
      main: '#F7F908', // Custom Secondary Color
    },
  },
  typography: {
    fontFamily: '"brolink", sans-serif',
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
        <ThemeProvider theme={theme}>
        <CssBaseline /> 
        <App />
      </ThemeProvider>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
